<ion-content class="container" scroll-y="true" fullscreen>
  <ion-grid>
    <ion-row (click)="close()" class="row1 ion-align-items-center ion-justify-content-center">
      <ion-col>
        <ion-card class="card1">
          <ion-card-content>
            <ion-row class="row1-1 ion-align-items-center ion-justify-content-center">
              <ion-col>
                <h2>¿Cómo te ayudamos?</h2>
              </ion-col>
            </ion-row>
            <ion-row class="row1-1 ion-align-items-center ion-justify-content-center">
              <ion-col>
                <ion-button (click)="goToLink()" class="btn-1" id="btnAsesoriaTelefonicaAyuda"> 
                  <ion-img src="assets/imgs/call_sin_sombra.svg"></ion-img>
                  Necesito asesoría telefónica
                </ion-button>
              </ion-col>
            </ion-row>
            <ion-row class="row1-2 ion-align-items-center ion-justify-content-center" id="btnAsesoriaWspAyuda">
              <ion-col>
                <ion-button (click)="ayudaWhatsappButton()" class="btn-1"> 
                  <ion-img src="assets/imgs/wsp_sin_sombra.svg"></ion-img>
                  Necesito asesoría por WhatsApp
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>